var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._m(0),_c('section',[_c('a-form',{staticClass:"context",attrs:{"form":_setup.form,"label-col":{ span: 2 },"wrapper-col":{ span: 5 },"labelAlign":"left"}},[_c('a-form-item',{attrs:{"label":"标签名称","required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'tag_name',
            {
              rules: [
                {
                  required: true,
                  message: '请输入标签名称',
                },
              ],
            },
          ]),expression:"[\n            'tag_name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入标签名称',\n                },\n              ],\n            },\n          ]"}],attrs:{"name":"tag_name","allowClear":"","placeholder":"请输入标签名称"}})],1),_c('a-form-item',{attrs:{"label":"标签类型","required":""}},[_c('a-radio-group',{attrs:{"name":"tag_type"},model:{value:(_setup.tag_type),callback:function ($$v) {_setup.tag_type=$$v},expression:"tag_type"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("手动标签")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("自动标签")])],1)],1),(_setup.tag_type == 2)?_c('div',[_c('a-form-item',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'pitch',
            {
              rules: [
                {
                  required: _setup.pitch.length == 0,
                  message: '至少选中一项',
                },
              ],
            },
          ]),expression:"[\n            'pitch',\n            {\n              rules: [\n                {\n                  required: pitch.length == 0,\n                  message: '至少选中一项',\n                },\n              ],\n            },\n          ]"}],attrs:{"label":"条件设置","required":""}},[_c('a-checkbox-group',{attrs:{"default-value":['A']},on:{"change":_setup.checkChange}},[_c('a-checkbox',{attrs:{"value":"A"}},[_c('div',{staticClass:"check-item"},[_c('span',{staticClass:"label"},[_vm._v("最近消费时间")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'time',
                    {
                      initialValue: 15,
                    },
                  ]),expression:"[\n                    'time',\n                    {\n                      initialValue: 15,\n                    },\n                  ]"}],staticStyle:{"width":"140px"},attrs:{"disabled":!_setup.theA}},[_c('a-select-option',{attrs:{"value":15}},[_vm._v("最近15天")]),_c('a-select-option',{attrs:{"value":30}},[_vm._v("最近30天")]),_c('a-select-option',{attrs:{"value":45}},[_vm._v("最近45天")]),_c('a-select-option',{attrs:{"value":90}},[_vm._v("最近90天")])],1)],1)]),_c('a-checkbox',{attrs:{"value":"B"}},[_c('div',{staticClass:"check-item"},[_c('span',{staticClass:"label"},[_vm._v("累计消费次数")]),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"num"},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'min_time',
                          {
                            rules: [
                              {
                                required: _setup.pitch.includes('B'),
                                message: '最小消费次数不能为空',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'min_time',\n                          {\n                            rules: [\n                              {\n                                required: pitch.includes('B'),\n                                message: '最小消费次数不能为空',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"min":0,"precision":0,"disabled":!_setup.pitch.includes('B')}}),_vm._v("次 ")],1)],1),_c('div',{staticClass:"line"},[_vm._v("——")]),_c('div',{staticClass:"num"},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'max_time',
                          {
                            rules: [
                              {
                                required: _setup.pitch.includes('B'),
                                message: '最大消费次数不能为空',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'max_time',\n                          {\n                            rules: [\n                              {\n                                required: pitch.includes('B'),\n                                message: '最大消费次数不能为空',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"min":0,"precision":0,"disabled":!_setup.pitch.includes('B')}}),_vm._v("次 ")],1)],1)])])]),_c('a-checkbox',{attrs:{"value":"C"}},[_c('div',{staticClass:"check-item"},[_c('span',{staticClass:"label"},[_vm._v("累计消费金额")]),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"num"},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'min_money',
                          {
                            rules: [
                              {
                                required: _setup.pitch.includes('C'),
                                message: '最小消费金额不能为空',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'min_money',\n                          {\n                            rules: [\n                              {\n                                required: pitch.includes('C'),\n                                message: '最小消费金额不能为空',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"min":0,"precision":2,"disabled":!_setup.pitch.includes('C')}}),_vm._v("元 ")],1)],1),_c('div',{staticClass:"line"},[_vm._v("——")]),_c('div',{staticClass:"num"},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'max_money',
                          {
                            rules: [
                              {
                                required: _setup.pitch.includes('C'),
                                message: '最大消费金额不能为空',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'max_money',\n                          {\n                            rules: [\n                              {\n                                required: pitch.includes('C'),\n                                message: '最大消费金额不能为空',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"min":0,"precision":2,"disabled":!_setup.pitch.includes('C')}}),_vm._v("元 ")],1)],1)])])]),_c('a-checkbox',{attrs:{"value":"D"}},[_c('div',{staticClass:"check-item"},[_c('span',{staticClass:"label"},[_vm._v("订单均价")]),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"num"},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'min_average',
                          {
                            rules: [
                              {
                                required: _setup.pitch.includes('D'),
                                message: '最小订单均价不能为空',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'min_average',\n                          {\n                            rules: [\n                              {\n                                required: pitch.includes('D'),\n                                message: '最小订单均价不能为空',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"min":0,"precision":2,"disabled":!_setup.pitch.includes('D')}}),_vm._v("元 ")],1)],1),_c('div',{staticClass:"line"},[_vm._v("——")]),_c('div',{staticClass:"num"},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'max_average',
                          {
                            rules: [
                              {
                                required: _setup.pitch.includes('D'),
                                message: '最大订单均价不能为空',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'max_average',\n                          {\n                            rules: [\n                              {\n                                required: pitch.includes('D'),\n                                message: '最大订单均价不能为空',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"min":0,"precision":2,"disabled":!_setup.pitch.includes('D')}}),_vm._v("元 ")],1)],1)])])])],1)],1)],1):_vm._e(),_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_setup.onSubmit}},[_vm._v("提交")]),_c('a-button',{staticClass:"btn",staticStyle:{"margin-left":"20px"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("返回")])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"title"},[_vm._v("客户标签 / 新建标签")])])
}]

export { render, staticRenderFns }